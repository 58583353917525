.iconCopy:hover {
    cursor: pointer;
}

.P1 {
    color: #FFFFFF !important;
    background: #13cf1c !important;
    border-color: #13cf1c !important;
}

.P2 {
    color: #FFFFFF !important;
    background: #ffbe45 !important;
    border-color: #ffbe45 !important;
}

.P3 {
    color: #FFFFFF !important;
    background: #FF7A45 !important;
    border-color: #FF7A45 !important;
}

.P4 {
    color: #FFFFFF !important;
    background: #CF1322 !important;
    border-color: #CF1322 !important;
}

.P5 {
    color: #FFFFFF !important;
    background: #8a0d17 !important;
    border-color: #8a0d17 !important;
}
