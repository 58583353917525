.containerItem {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.label {
    color: #767f8d;
}

.text {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 3px;
    color: #303742;
}

.text>span>svg {
    transform: scale(1.2, 1.2);
}

.text>span {
    margin-right: 5px;
}