.collapse {
  background-color: white;
  font-family: 'Open Sans', sans-serif;
}

.marginAllOver {
  margin: 5px;
}

.content {
  border: none;
}

.collapseHeader {
  padding: 6px 10px;
  display: flex;
}

.collapseTitleText {
  color: dimgray;
  font-size: 1rem;
}

.collapseTitleIcon {
  width: 30px;
  margin-right: 15px;
}

.verticalAlign {
  display: inline-block;
  padding-top: 5px;
}

.rightAlign {
  float: right;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.onlyMarginBottom {
  margin: 0 0 5px 0;
}
